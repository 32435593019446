import React from "react"
import {graphql, Link} from "gatsby"
import Container from "../../components/Container/Container";
import {Edge} from "../../pages";
import styles from "./Category.module.scss";
import PageHeader from "../../components/PageHeader/PageHeader";

export const query = graphql`
  query($slug: String!, $id: String) {
    contentfulPages(slug: { eq: $slug }) {
      title
    },
  allContentfulBlogPost ( filter: { category: { contentful_id: { eq: $id }}}, sort: { fields: publicationDate, order: DESC } ) {
    edges {
      node {
        title,
        slug,
        publicationDate(formatString:"DD/MM/YYYY"),
        thumbnail {
          file {
            url
          }
        }
      }
    }
   }
  }
`

const Category = (props: any) => {
  return <Container className={styles.container}>
    <PageHeader header={props.data.contentfulPages.title}/>
    <ul className={styles.list}>
      {props.data.allContentfulBlogPost.edges.map((edge: Edge) => (
        <li>
          <Link to={`/blog/${edge.node.slug}`}>
            <div className={styles.dateWrapper}>
              <img src={edge.node.thumbnail.file.url} alt={edge.node.title}/>
              <div className={styles.h5Wrapper}><h5>{edge.node.publicationDate}</h5></div>
            </div>
            <h4>{edge.node.title}</h4>
          </Link>
        </li>
      ))}
    </ul>
  </Container>;
};

export default Category;
