import * as React from 'react'

import '../../styles/index.scss';
import styles from "./PageHeader.module.scss";

interface Props {
  header: string | JSX.Element,
  description?: string | JSX.Element,
  reverseOrder?: boolean
}

const PageHeader: React.FC<Props> = (props: Props): JSX.Element => {
  const classNames = [styles.header];
  if (props.reverseOrder) {
    classNames.push(styles.reversedOrder)
  }
  return <div className={classNames.join(" ")}>
    <h1>{props.header}</h1>
    {props.description && <h5>{props.description}</h5>}
  </div>
}

export default PageHeader
